<template>

  <v-container
    fluid
    tag="section"
  >

  <v-dialog  v-model="dlg_confirma_save"  width="500">
    <v-card>
      <v-card-title class="text-h4 blue lighten-3; padding: 0 !important">
        CONFIRMAR INFORMAÇÕES
        
      </v-card-title>

      <v-card-text>
        <b>Placa:</b> {{ this.item.placa }} <br/>
        <b>Descrição:</b> {{ this.item.descricao }} <br/>
        <b>Categoria:</b> {{ this.item.categoria }} 
        <b>Tipo:</b> {{ this.item.tipo }} <br/>
        <b>Fabricante:</b> {{ this.item.fabricante }} 
        <b>Ano de Fabricação:</b> {{ this.item.ano_fabricacao }} <br/>
        <b>Garantia:</b> {{ this.item.data_garantia }}  <br/>
        <b>Modelo:</b> {{ this.item.modelo }}  <br/>
        <b>Número de Série:</b> {{ this.item.numero_serie }}  <br/>

        <b>Peso:</b> {{ this.item.peso }} 
        <b>Largura:</b> {{ this.item.largura }} 
        <b>Altura:</b> {{ this.item.altura }} <br/>
        <b>Estado:</b> {{ this.item.estado }} <br/>


        
        <b>Proprietário:</b> {{ this.item.proprietario }} <br/>
        <b>Localização:</b> {{ this.item.departamento }} <br/>
        <b>Observação:</b> {{ this.item.obs }} <br/>

      </v-card-text>
      
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="primary" 
        text @click="dlg_confirma_save = false"
        >
        FECHAR
      </v-btn>


        <v-btn
          color="primary"
           @click="salva()"
          >
          CONFIRMAR
        </v-btn>

       

      </v-card-actions>
    </v-card>
</v-dialog>


  <v-dialog  v-model="dlg_delete"  width="500">
    <v-card>
      <v-card-title class="text-h5 red lighten-2">
        Mensagem
      </v-card-title>
  
      <v-card-text>
          Deseja inativar o registro?
      </v-card-text>
  
      <v-divider></v-divider>
  
      <v-card-actions>
        <v-spacer></v-spacer>
  
        <v-btn
          color="secundary" 
          text
          @click="dlg_delete = false"
        >
          Cancelar
        </v-btn>
  
        <v-btn
          color="red"
          @click="setConfirmDelete"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text> 
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>




<base-material-card
      icon="mdi-shopping"
      title="Produto"
      class="px-5 py-3">
<v-form ref="form">
    <v-card elevation="0">

    <v-card-text >
      <v-container>
        <v-row>



          <v-col cols="6" sm="6">
            <v-text-field 
              label="Placa " style="margin-left:4px;"
              filled v-model="item.placa" x-large
            ></v-text-field>
          </v-col>
      
          <v-col cols="6" sm="6">
            <v-text-field 
              label="Descrição *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="item.descricao" x-large
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6">
              <v-select label="Categoria *" v-model="item.categoria"  :items="categorias"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>
          </v-col>


          <v-col cols="6" sm="6">
            <v-select label="Tipo *" v-model="item.tipo"  :items="tipos"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>

          </v-col>


          <v-col cols="6" sm="6">
            <v-text-field 
              label="Fabricante *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="item.fabricante" x-large
            ></v-text-field>
          </v-col>


          <v-col cols="6" sm="6">
            <v-text-field 
              label="Ano Fabricação"  style="margin-left:4px;"
              filled v-model="item.ano_fabricacao" x-large
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6">
            <v-text-field 
              label="Modelo"  style="margin-left:4px;"
              filled v-model="item.modelo" x-large
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6">
            <v-text-field 
            label="Data da Garantia" small 
            filled v-model="item.data_garantia" 
            v-mask="'##/##/####'" 
          ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6">
            <v-text-field 
              label="Número de Série"  style="margin-left:4px;"
              filled v-model="item.numero_serie" x-large
            ></v-text-field>
          </v-col>




          <v-col cols="6" sm="6">
            <v-text-field 
              label="Peso"   style="margin-left:4px;"
              filled v-model="item.peso" x-large
            ></v-text-field>
          </v-col>


          <v-col cols="6" sm="6">
            <v-text-field 
              label="Altura"  style="margin-left:4px;"
              filled v-model="item.altura" x-large
            ></v-text-field>
          </v-col>


          <v-col cols="6" sm="6">
            <v-text-field 
              label="Largura" autofocusstyle="margin-left:4px;"
              filled v-model="item.largura" x-large
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="6">
            <v-select label="Estado *" v-model="item.estado"  :items="estado"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>
           </v-col>


          <v-col cols="6" sm="6">
            <v-select label="Proprietário *" v-model="item.proprietario"  :items="empresas"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled ></v-select>
           </v-col>


          <v-col cols="6" sm="6">
            <v-select label="Utilização *" v-model="item.departamento"  :items="lista_departamento_select"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled></v-select>
           </v-col>



          <v-col cols="6" sm="6">
            <v-text-field 
              label="Obs."  style="margin-left:4px;"
              filled v-model="item.obs" x-large
            ></v-text-field>
          </v-col>

        </v-row>
                          * campos obrigatórios
                     

                          

      </v-container> 

    </v-card-text>
    <v-space></v-space>

    <v-card-actions class="justify-end">
      <v-btn color="success" :loading="btn_load"  @click="dlg_confirma_save = true">
        Salvar
        <v-icon right dark>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    </v-card>


   


  </v-form>  
</base-material-card>
  </v-container>    
</template>
<script>

import Documento from '../../services/documento'
import Item from '../../services/item'

  export default {
    name: 'item',
   
    mounted () {
      this.lista_departamento();
    },


    

    data () {
      return {
        components: {
            DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        headers: [
          { text: 'Descrição', value: 'desricao' },
          { text: 'Empresa', value: 'empresa' },

        ],
        dialog:true,
        dlg_delete: false,
        dlg_confirma_save:false,
        alert_msg:false,
        showload_img: false,
        currentFile: undefined,
          progress: 0,
        msg_dialog:'',
        btn_load: false,
        items: [],
        departamentos: [],
        lista_departamento_select: [],
        empresas: ["SEDE CORPORATIVA", "CEMITÉRIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PARQUE DAS FLORES", "PREVIDA", "MELRO E SAMPARIO", "RESERVA DA PAZ",  "GPF - CORRETORA"],
        departamentoMapping: {}, // Objeto para mapear texto para id
        estado: ['NOVO', 'SEMI-NOVO', 'COM DEFEITO'],
        categorias: [
            'Suprimentos de Escritório',
            'Mobiliário de Escritório',
            'Tecnologia',
            'Papelaria',
            'Equipamento de Apresentação',
            'Materiais de Limpeza',
            'Armazenamento',
            'Eletrônicos',
            'Segurança',
            'Cuidados com os Funcionários',
            'Móveis',
            'Eletroeletrônicos',
            'Outros'
        ],

        tipos: [
            'Cadeira de Escritório',
            'Mesa de Escritório',
            'Notebook',
            'Computador Desktop',
            'Impressora',
            'Telefone VoIP',
            'Projetor',
            'Ar-Condicionado',
            'Refrigerador',
            'Máquina de Café',
            'Micro-ondas',
            'Forno Elétrico',
            'Máquina de Lavar',
            'Bebedouro',
            'Ventilador',
            'Máquina de Fax',
            'Máquina de Xerox',
            'Câmera de Segurança',
            'Mesa de Conferência',
            'Cadeira de Reunião',
            'Armário de Arquivo',
            'Equipamentos de Rede',
            'Materiais de Escrita',
            'Material de Limpeza',
            'Papelaria',
            'Iluminação de Escritório',
            'Produtos de Segurança',
            'Equipamentos de Cozinha',
            'Equipamentos de Banheiro',
            'Materiais de Alimentação',
            'Equipamentos de Vídeo',
            'Quadro Branco',
            'Quadro de Aviso',
            'Telefone Fixo',
            'Cadeira de Visita',
            'Estante',
            'Mesa de Centro',
            'Relógio de Parede',
            'Máquina de Cópia',
            'Cadeira de Computador',
            'Estação de Trabalho',
            'Projetor Multimídia',
            'Sistema de Som',
            'Móveis de Jardim',
            'Frigobar',
            'Cafeteira',
            'Liquidificador',
            'Fotocopiadora',
            'Estabilizador',
            'Servidor de Rede',
            'Produtos de Escritório',
            'Produtos de Manutenção',
            'Equipamentos de Armazenamento',
            'Suprimentos de Informática',
            'Produtos de Papelaria',
            'Produtos de Organização',
            'Produtos de Cozinha',
            'Produtos de Banheiro',
            'Produtos de Segurança no Trabalho',
            'Outros Itens'
          ],

        item:{
            descricao:'',
            tipo: '',
            fabricante: '',
            categoria: '',
            ano_fabricacao: '',
            peso: '',
            largura: '',
            altura: '',
            placa: '',
            departamento: '',
            obs: '',
            proprietario: '',
            empresa: '',
            id_departamento: '',
            nome_departamento: '',
            estado: '',
            numero_serie: '',
            data_garantia: '',
            modelo: ''
        },
        loading:false,
      }
    },

   
    methods: {

        clear () {
          this.$refs.form.reset();
        },
  

      ok_val(){
        this.$router.push('login');
      },


      lista_departamento(){
        Documento.lista().then(response => {
                if(response.status = 200){this.departamentos = response.data;
                  this.departamentos = response.data;
                  this.lista_departamento_select = this.departamentos.map((departamento) => {
                  const texto = `${departamento.descricao} - ${departamento.empresa}`;
                  this.departamentoMapping[texto] = departamento.id;
                  return texto;
                  });
                }
              }).catch(e => {
              }).finally(() => {
                
            });

        },

    
   
      salva(){
        this.dlg_confirma_save = false;
        const id = this.departamentoMapping[this.item.departamento];
        this.item.id_departamento = id;
        this.item.nome_departamento = this.item.departamento;

        this.btn_load = true;

        if(this.$refs.form.validate()){

              Item.salva(this.item).then(response => {

                    if(response.data.codigo){
                        this.msg_dialog = response.data.mensagem;
                        this.alert_msg = true;
                     
                    }

                  }).catch(e => {

                   if(e == 'Error: timeout of 8000ms exceeded'){
                      this.loading = false
                      this.alert_msg = true;
                      this.btn_load = false;
                      this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";
                    }
                }).finally(() => {
                    this.loading = false
                    this.btn_load = false;
                    this.clear();
                   this.alert_msg = true;
                    this.msg_dialog = "Produto cadastrado com sucesso!";

                  })
               
        }else{
            this.alert_msg = true;
            this.btn_load = false;
            this.msg_dialog = "Preencha todos os campos";

        }
      },



    }

  }


  
</script>