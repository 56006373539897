import { http } from './config'
import authHeader from './auth-header';


export default {

    salva: (data) => {
        return http.post('/item/salva', data, {headers: authHeader()})
    },

    deleta: (data) => {
        return http.get('/item/deleta/'+data,  {headers: authHeader()})
    },
    
    lista: () => {
        return http.get('/item/lista/', {headers: authHeader()})
    },

    lista_movimentacao: (data) => {
        return http.get('/item/lista-movimentacao/'+ data, {headers: authHeader()})
    },

    lista_documentos: (data) => {
        return http.get('/item/lista-documentos/'+ data, {headers: authHeader()})
    },

    
    salva_movimentcao: (data) => {
        return http.post('/item/salva-movimentacao', data, {headers: authHeader()})
    },
    
    upload: (data) => {
        
        const user = JSON.parse(sessionStorage.getItem('user'))

        const headers = {  
            "Content-Type": "multipart/form-data",
            'Authorization': 'Bearer ' + user.accessToken
        }

        http.defaults.timeout = 8000;
        return http.post('/item/salva-documento', data, {headers: headers})

    },

    deleta: (id) => {
        return http.get('/item/delete-documento/'+ id,  {headers: authHeader()})
    },

    lista_filtro: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {
        const  params ={
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': opcao,
            'filtro': filtro,
            'categoria': tipo,
        }
    
        // Fazer a solicitação HTTP usando o Axios e passar os cabeçalhos configurados
        return http.get('/item/lista', {
            params: params,
            headers: authHeader() 

      });
    },

    lista_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': opcao,
            'filtro': filtro,
            'categoria': tipo,
          };

       
          return http.get('/item/lista-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

   
    lista_receita_xls: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao) => {

        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao
          };

       
          return http.get('/planilha-dash/lista-contrato-receita-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });

          
    },

    download: (id) => {
        const params = new URLSearchParams([['id', id]])
        const user = JSON.parse(sessionStorage.getItem('user'))
        const headers = {  
            "Content-Type": "multipart/form-data; charset=ISO-8859-1",
            'Authorization': 'Bearer ' + user.accessToken
            }


        return http.get('/item/download-documento/' +id, {headers: headers, responseType: 'blob' })

        //return http.get('download', {headers: authHeader()})
    }


}
