import { http } from './config'
import authHeader from './auth-header';


export default {

    salva: (data) => {
        return http.post('/departamento/salva', data, {headers: authHeader()})
    },

    deleta: (data) => {
        return http.get('/departamento/deleta/'+data,  {headers: authHeader()})
    },
    
    lista: () => {
        return http.get('/departamento/lista/', {headers: authHeader()})
    },

   

}
